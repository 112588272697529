<template>
    <div>
        <landing-header></landing-header>
        <main class="static-page">
            <div class="m-3 p-3 text-center">
                <h4>TERMS AND CONDITIONS FOR LOCAL SHIPMENTS (INTRASTATE)</h4>
            </div>
            <section class="p-5">
                <p>Intrastate shipments are delivered on the same day (for items picked up before 9am), or
on the next working day (for items picked up after 9am). (Note: FTD only operates from
Monday to Friday.)
</p>
                <p>Content of parcel being shipped must be declared by the client. (FTD does not deliver
perishable items such as food and drinks)
</p>
                <p>3.) Value of all parcels must be declared by the client.</p>
                <p>4.) Parcels valued less than $25 (N10,000) by client are covered under FTD insurance at no
additional cost, and clients will be reimbursed in the event of damage or loss of such
parcels.
</p>
                <p>5.) For parcels valued over $25 (N10,000) by client, an extra charge of 10% of the declared
value will be added to the shipping cost. Clients will be reimbursed in the event of
damage or loss of such parcels.
</p>
                <p>6.) Payment for deliveries are to be made via bank transfer before the parcel is picked up,
and are non-refundable.
</p>
                <p>7.) Deliveries will be attempted at most twice before being returned to the sender. (eg. in the
event that the recipient is unreachable, delivery cannot be completed.)
</p>        
            <p>
                8.) Returning shipment back to the client will incur an extra charge, same as cost of
shipping the parcel. (returned shipment can be picked up by client at FTD Logistics office
at no extra charge)
            </p>
            <p>
                9.) Parcels delivered to the international airport and trade fair incur an extra fee of N500.
            </p>
            <p>
                10.) FTD Logistics charges 2% of payments on all payments collected on behalf of
merchants
            </p>
            </section>
        </main>
        <landing-footer></landing-footer>
    </div>
</template>
<script>
import footerVue from '../layout/footer.vue'
import headerVue from '../layout/header.vue'
export default {
    name: "LocalShipmentTerms",
    components: {landingHeader: headerVue, landingFooter: footerVue}
}
</script>